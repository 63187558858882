
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumbs/breadcrumb";
import CodeHighlighter from "@/view/content/highlighters/CodeHighlighter.vue";

export default defineComponent({
  name: "background",
  components: {
    CodeHighlighter
  },
  setup() {
    const colors = ref({
      backgroundColors: [
        "white",
        "primary",
        "light",
        "secondary",
        "success",
        "info",
        "warning",
        "danger",
        "dark"
      ],
      lightBackgroundColors: [
        "primary",
        "success",
        "info",
        "warning",
        "danger",
        "dark"
      ],
      opacity: [0, 5, 10, 15, 20, 25, 50, 75, 100]
    });

    onMounted(() => {
      setCurrentPageTitle("Background");
    });

    return {
      colors
    };
  }
});
